module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-plugin-hfn-profile/gatsby-browser.js'),
      options: {"plugins":[],"pathToRootReducerModule":"./src/store/reducers/app","firebaseAuthConfig":{"apiKey":"AIzaSyC_D--AX_STmQi4Ew6zbpKNFxkiCQpevHE","authDomain":"auth.heartfulness.org","projectId":"unifiedplatform"},"splashScreen":"loading..."},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-5C7D2RN"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dd3453937df7f054fafc5002f1dbce19"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
