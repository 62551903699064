require("./src/assets/styles/index.scss");
require("sites-common/gatsby-utils/setupOfficeFabric");
require("slick-carousel/slick/slick.css");
require("slick-carousel/slick/slick-theme.css");
require("react-multi-carousel/lib/styles.css");

require("rc-slider/assets/index.css");
const RootWrapper = require("sites-common/gatsby-utils/WrapRoot").default;

exports.wrapRootElement = RootWrapper;
// End slash removing for Seo optimization
exports.onRouteUpdate = ({ location }) => {
  if (location.pathname.endsWith("/") && location.pathname !== "/") {
    const newPath = location.pathname.slice(0, -1);
    window.history.replaceState({}, "", newPath + location.search);
  }
};
