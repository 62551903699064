// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blue-initiatives-jsx": () => import("./../../../src/pages/blue-initiatives.jsx" /* webpackChunkName: "component---src-pages-blue-initiatives-jsx" */),
  "component---src-pages-book-a-stay-index-jsx": () => import("./../../../src/pages/book-a-stay/index.jsx" /* webpackChunkName: "component---src-pages-book-a-stay-index-jsx" */),
  "component---src-pages-book-a-stay-pearl-jsx": () => import("./../../../src/pages/book-a-stay/pearl.jsx" /* webpackChunkName: "component---src-pages-book-a-stay-pearl-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-event-2022-12-fesa-oceania-seminar-jsx": () => import("./../../../src/pages/event/2022/12/fesa-oceania-seminar.jsx" /* webpackChunkName: "component---src-pages-event-2022-12-fesa-oceania-seminar-jsx" */),
  "component---src-pages-event-2022-4-free-meditation-event-kanha-shanti-vanam-jsx": () => import("./../../../src/pages/event/2022/4/free-meditation-event-kanha-shanti-vanam.jsx" /* webpackChunkName: "component---src-pages-event-2022-4-free-meditation-event-kanha-shanti-vanam-jsx" */),
  "component---src-pages-event-2022-7-free-meditation-event-kanha-shanti-vanam-jsx": () => import("./../../../src/pages/event/2022/7/free-meditation-event-kanha-shanti-vanam.jsx" /* webpackChunkName: "component---src-pages-event-2022-7-free-meditation-event-kanha-shanti-vanam-jsx" */),
  "component---src-pages-event-2022-9-free-meditation-event-kanha-shanti-vanam-jsx": () => import("./../../../src/pages/event/2022/9/free-meditation-event-kanha-shanti-vanam.jsx" /* webpackChunkName: "component---src-pages-event-2022-9-free-meditation-event-kanha-shanti-vanam-jsx" */),
  "component---src-pages-event-2022-9-kanha-shanti-vanam-bhandara-18-f-2-c-85-f-94-a-1-47-f-6-bb-97-0-e-7-a-4-b-5-bbf-47-jsx": () => import("./../../../src/pages/event/2022/9/kanha-shanti-vanam-bhandara/18f2c85f-94a1-47f6-bb97-0e7a4b5bbf47.jsx" /* webpackChunkName: "component---src-pages-event-2022-9-kanha-shanti-vanam-bhandara-18-f-2-c-85-f-94-a-1-47-f-6-bb-97-0-e-7-a-4-b-5-bbf-47-jsx" */),
  "component---src-pages-event-2022-9-quantum-healing-jsx": () => import("./../../../src/pages/event/2022/9/quantum-healing.jsx" /* webpackChunkName: "component---src-pages-event-2022-9-quantum-healing-jsx" */),
  "component---src-pages-event-2023-1-kanha-shanti-vanam-bhandara-a-7-d-7-e-270-1-ea-9-4-c-5-e-a-222-47-ac-25-cc-839-c-jsx": () => import("./../../../src/pages/event/2023/1/kanha-shanti-vanam-bhandara/a7d7e270-1ea9-4c5e-a222-47ac25cc839c.jsx" /* webpackChunkName: "component---src-pages-event-2023-1-kanha-shanti-vanam-bhandara-a-7-d-7-e-270-1-ea-9-4-c-5-e-a-222-47-ac-25-cc-839-c-jsx" */),
  "component---src-pages-event-2023-1-quantum-healing-jsx": () => import("./../../../src/pages/event/2023/1/quantum-healing.jsx" /* webpackChunkName: "component---src-pages-event-2023-1-quantum-healing-jsx" */),
  "component---src-pages-event-2023-3-divya-janani-jsx": () => import("./../../../src/pages/event/2023/3/divya-janani.jsx" /* webpackChunkName: "component---src-pages-event-2023-3-divya-janani-jsx" */),
  "component---src-pages-event-2023-3-divya-janani-registration-jsx": () => import("./../../../src/pages/event/2023/3/divya-janani-registration.jsx" /* webpackChunkName: "component---src-pages-event-2023-3-divya-janani-registration-jsx" */),
  "component---src-pages-event-2023-4-kanha-shanti-vanam-bhandara-95450-f-32-65-fd-4137-a-151-cb-43-be-73-c-182-jsx": () => import("./../../../src/pages/event/2023/4/kanha-shanti-vanam-bhandara/95450f32-65fd-4137-a151-cb43be73c182.jsx" /* webpackChunkName: "component---src-pages-event-2023-4-kanha-shanti-vanam-bhandara-95450-f-32-65-fd-4137-a-151-cb-43-be-73-c-182-jsx" */),
  "component---src-pages-event-2023-4-preceptor-seminar-jsx": () => import("./../../../src/pages/event/2023/4/preceptor-seminar.jsx" /* webpackChunkName: "component---src-pages-event-2023-4-preceptor-seminar-jsx" */),
  "component---src-pages-event-2023-5-vow-to-wow-jsx": () => import("./../../../src/pages/event/2023/5/vow-to-wow.jsx" /* webpackChunkName: "component---src-pages-event-2023-5-vow-to-wow-jsx" */),
  "component---src-pages-event-2023-8-divya-janani-registration-index-jsx": () => import("./../../../src/pages/event/2023/8/divya-janani-registration/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-8-divya-janani-registration-index-jsx" */),
  "component---src-pages-event-2023-8-vow-to-wow-index-jsx": () => import("./../../../src/pages/event/2023/8/vow-to-wow/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-8-vow-to-wow-index-jsx" */),
  "component---src-pages-event-2023-8-world-spritual-conference-2-index-jsx": () => import("./../../../src/pages/event/2023/8/world-spritual-conference-2/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-8-world-spritual-conference-2-index-jsx" */),
  "component---src-pages-event-2023-8-world-spritual-conference-index-jsx": () => import("./../../../src/pages/event/2023/8/world-spritual-conference/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-8-world-spritual-conference-index-jsx" */),
  "component---src-pages-event-2023-9-brain-development-index-jsx": () => import("./../../../src/pages/event/2023/9/brain-development/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-9-brain-development-index-jsx" */),
  "component---src-pages-event-2023-9-eurythmy-index-jsx": () => import("./../../../src/pages/event/2023/9/eurythmy/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-9-eurythmy-index-jsx" */),
  "component---src-pages-event-2023-9-quantum-therapy-jsx": () => import("./../../../src/pages/event/2023/9/quantum-therapy.jsx" /* webpackChunkName: "component---src-pages-event-2023-9-quantum-therapy-jsx" */),
  "component---src-pages-event-2023-9-shiatsu-family-massage-index-jsx": () => import("./../../../src/pages/event/2023/9/shiatsu-family-massage/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-9-shiatsu-family-massage-index-jsx" */),
  "component---src-pages-event-2023-9-sound-healing-index-jsx": () => import("./../../../src/pages/event/2023/9/sound-healing/index.jsx" /* webpackChunkName: "component---src-pages-event-2023-9-sound-healing-index-jsx" */),
  "component---src-pages-event-ack-jsx": () => import("./../../../src/pages/event/ack.jsx" /* webpackChunkName: "component---src-pages-event-ack-jsx" */),
  "component---src-pages-event-payment-ack-jsx": () => import("./../../../src/pages/event-payment/ack.jsx" /* webpackChunkName: "component---src-pages-event-payment-ack-jsx" */),
  "component---src-pages-event-payment-index-jsx": () => import("./../../../src/pages/event-payment/index.jsx" /* webpackChunkName: "component---src-pages-event-payment-index-jsx" */),
  "component---src-pages-event-quantum-healing-jsx": () => import("./../../../src/pages/event/quantum-healing.jsx" /* webpackChunkName: "component---src-pages-event-quantum-healing-jsx" */),
  "component---src-pages-eventregister-jsx": () => import("./../../../src/pages/eventregister.jsx" /* webpackChunkName: "component---src-pages-eventregister-jsx" */),
  "component---src-pages-events-detail-jsx": () => import("./../../../src/pages/events/detail.jsx" /* webpackChunkName: "component---src-pages-events-detail-jsx" */),
  "component---src-pages-events-index-jsx": () => import("./../../../src/pages/events/index.jsx" /* webpackChunkName: "component---src-pages-events-index-jsx" */),
  "component---src-pages-events-listing-jsx": () => import("./../../../src/pages/events-listing.jsx" /* webpackChunkName: "component---src-pages-events-listing-jsx" */),
  "component---src-pages-events-listing-static-jsx": () => import("./../../../src/pages/events-listing-static.jsx" /* webpackChunkName: "component---src-pages-events-listing-static-jsx" */),
  "component---src-pages-events-paid-event-status-jsx": () => import("./../../../src/pages/events/PaidEventStatus.jsx" /* webpackChunkName: "component---src-pages-events-paid-event-status-jsx" */),
  "component---src-pages-events-pricing-details-jsx": () => import("./../../../src/pages/events/PricingDetails.jsx" /* webpackChunkName: "component---src-pages-events-pricing-details-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../../../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-green-initiatives-jsx": () => import("./../../../src/pages/green-initiatives.jsx" /* webpackChunkName: "component---src-pages-green-initiatives-jsx" */),
  "component---src-pages-greenkanha-jsx": () => import("./../../../src/pages/greenkanha.jsx" /* webpackChunkName: "component---src-pages-greenkanha-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kmc-index-jsx": () => import("./../../../src/pages/kmc/index.jsx" /* webpackChunkName: "component---src-pages-kmc-index-jsx" */),
  "component---src-pages-living-in-kanha-jsx": () => import("./../../../src/pages/living-in-kanha.jsx" /* webpackChunkName: "component---src-pages-living-in-kanha-jsx" */),
  "component---src-pages-my-registrations-jsx": () => import("./../../../src/pages/my-registrations.jsx" /* webpackChunkName: "component---src-pages-my-registrations-jsx" */),
  "component---src-pages-pnr-index-jsx": () => import("./../../../src/pages/pnr/index.jsx" /* webpackChunkName: "component---src-pages-pnr-index-jsx" */),
  "component---src-pages-service-jsx": () => import("./../../../src/pages/service.jsx" /* webpackChunkName: "component---src-pages-service-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-special-accommodation-ack-jsx": () => import("./../../../src/pages/special-accommodation/ack.jsx" /* webpackChunkName: "component---src-pages-special-accommodation-ack-jsx" */),
  "component---src-pages-special-accommodation-index-jsx": () => import("./../../../src/pages/special-accommodation/index.jsx" /* webpackChunkName: "component---src-pages-special-accommodation-index-jsx" */),
  "component---src-pages-the-ashram-jsx": () => import("./../../../src/pages/the-ashram.jsx" /* webpackChunkName: "component---src-pages-the-ashram-jsx" */),
  "component---src-pages-visitors-index-jsx": () => import("./../../../src/pages/visitors/index.jsx" /* webpackChunkName: "component---src-pages-visitors-index-jsx" */),
  "component---src-pages-visitors-register-jsx": () => import("./../../../src/pages/visitors/register.jsx" /* webpackChunkName: "component---src-pages-visitors-register-jsx" */)
}

